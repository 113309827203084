<template lang="pug">
div(class="mb-10 xl:mb-12")
  p.title-400.mb-6 Commodités
  div
    .flex.flex-wrap
      label(class="radio-button inline-block mr-4 pb-2 relative text-base cursor-pointer select-none" v-for="facility in accommodationFacilities" :key="facility.value")
        input(class="radio-button__input absolute opacity-0 cursor-pointer h-0 w-0" type="checkbox" name="facility" :value="facility.value" v-model="selectedValues")
        p(class="radio-button__checkmark btn-outline  btn-lg  btn-outline-primary transition px-6 duration-500 ease-in-out")
          span {{ facility.label }}
</template>

<script>
export default {
  props: {
    initialFacilities: {
      type: Array,
      default: () => {
        return []
      }
    },

    accommodationFacilities: {
      type: Array,
      required: true
    }
  },

  data () {
    return {
      selectedValues: []
    }
  },

  watch: {
    selectedValues () {
      this.$store.commit('updateParams', { key: 'by_facilities', value: this.selectedValues })

      this.$store.dispatch('searchAccommodations')
        .then(({ facilitiesLabel }) => {
          this.$emit('change', facilitiesLabel)
        })
    }
  },

  mounted () {
    this.selectedValues = [...this.initialFacilities]

    this.$store.commit('updateReady', 'facilities')
  }
}
</script>
