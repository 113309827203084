<template lang="pug">
  active-item(:is-active="$mq === 'mobile'")
    .dropdown.relative.inline-block.w-full.mr-6(:class="{ active: active }" slot-scope="{ setActive, active }" v-click-outside="() => { if ($mq === 'desktop') return setActive(false) }")
      .dropdown__btn.w-full.border-2.border-gray-400.relative.text-base.text-gray.font-normal.py-select.pl-8.pr-16.text-left.inline-block.rounded.leading-10.overflow-hidden(class="hidden xl:block" @click.prevent="if ($mq === 'desktop') setActive(!active); return")
        .line-clamp-1 {{ label }}

      collapse-transition
        .dropdown__menu.w-full.rounded.top-dropdown.bg-white.pb-20.px-12.left-0.z-40(class="xl:min-w-dropdown-location xl:py-8 xl:shadow xl:w-auto xl:absolute" v-show="active")
          p.title-400.mb-6 Villes

          multiselect(
            ref="multiselect"
            id="ajax"
            :hide-selected="true"
            :options="options"
            label="text"
            track-by="id"
            group-values="values"
            group-label="label"
            :clear-on-select="false"
            :group-select="false"
            :internal-search="false"
            :close-on-select="false"
            placeholder="Ville"
            select-label=""
            open-direction="top"
            @search-change="asyncFind"
            @select="handleSelect"
          )
            p.multiselect__option.bg-white.w-full(slot="noResult") Aucun résultat
            p.multiselect__option.bg-white.w-full(slot="noOptions") Veuillez choisir une ville

          vue-tags-input(
            v-model="tag"
            :tags="selectedLocations"
            @tags-changed="newLocations => selectedLocations = newLocations"
          )

          div(v-if="selectedCities.length === 1")
            button.link.link-underline(@click.prevent="toggleRange") Élargir ma recherche

            div(v-if="showRange")
              vue-slider(
                :min="0"
                :max="200"
                tooltip="always"
                v-model="range"
                :tooltip-formatter="formattedRange"
              )
              .flex.justify-between.text-base.text-gray.mt-6
                span 0 km
                span 200 km
</template>

<script>
import axios from 'axios'
import { debounce } from 'lodash'
import VueSlider from 'vue-slider-component'
import VueTagsInput from '@johmun/vue-tags-input'
import { formatCities, formatSectors, formatLocations } from "@utils/locations-helpers"

export default {
  components: {
    VueSlider,
    VueTagsInput
  },

  props: {
    initialCities: {
      type: Array,
      default: () => {
        return []
      }
    },

    initialSectors: {
      type: Array,
      default: () => {
        return []
      }
    },

    searchParams: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      tag: '',
      range: 0,
      showRange: false,
      selectedLocations: [],
      options: [],
      label: 'Villes'
    }
  },

  computed: {
    selectedCities () {
      return this.selectedLocations.filter(location => location.type === 'city')
    },

    selectedSectors () {
      return this.selectedLocations.filter(location => location.type === 'sector')
    },

    initialSelectedLocations () {
      const initialCities = formatCities(this.initialCities)
      const initialSectors = formatSectors(this.initialSectors)

      return [
        ...initialCities,
        ...initialSectors
      ]
    }
  },

  watch: {
    selectedLocations () {
      if (this.selectedCities.length > 1) {
        this.selectedCities.forEach(location => {
          location.text = location.originalText
        })

        this.$store.commit('deleteParams', 'by_range')
        this.showRange = false
      }

      this.$store.commit('updateParams', { key: 'by_cities', value: this.selectedCities.map(city => city.id) })
      this.$store.commit('updateParams', { key: 'by_sectors', value: this.selectedSectors.map(sector => sector.id) })

      this.$store.dispatch('searchAccommodations')
        .then(({ locationsLabel }) => {
          this.label = locationsLabel
        })
    },

    range: debounce(function () {
      const city = this.selectedCities[0]

      if (parseInt(this.range) === 0) {
        this.$store.commit('deleteParams', 'by_range')

        city.text = city.originalText
      } else {
        city.text = `${city.originalText} - ${this.range} km`

        this.$store.commit('updateParams', { key: 'by_range', value: this.range })
      }

      this.$store.dispatch('searchAccommodations')
        .then(({ locationsLabel }) => {
          this.label = locationsLabel
        })
    }, 300)
  },

  mounted () {
    if (this.searchParams.by_range) {
      this.range = parseInt(this.searchParams.by_range)
      this.showRange = true
    }

    this.selectedLocations = this.initialSelectedLocations

    this.$nextTick(() => {
      this.$store.commit('updateReady', 'locationsSelect')
    })
  },

  methods: {
    formattedRange (value) { return `${value} km` },

    handleSelect (selectedOption) {
      if (!Array.isArray(selectedOption)) {
        this.selectedLocations.push(selectedOption)
      }
    },

    asyncFind (query) {
      this.search(query, this)
    },

    search: debounce((query, vm) => {
      axios.get('/api/v1/locations', {
        params: {
          query
        }
      })
        .then(({ data }) => {
          vm.options = formatLocations(data)
        })
    }, 350),

    toggleRange () {
      this.showRange = !this.showRange

      if (this.showRange === false) {
        this.range = 0
      }
    }
  }
}
</script>
