import { Controller } from "stimulus"

export default class extends Controller {
  connect () {
    if (!localStorage.getItem("PopupOpenAt")) {
      setTimeout(() => {
        if (window.modal) {
          localStorage.setItem("PopupOpenAt", Date.now())
          window.modal.open("#share-modal")
        }
      }, "2000")
    }
  }
}
