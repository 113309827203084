<template lang="pug">
  active-item(:is-active="$mq === 'mobile'")
    .dropdown.relative.inline-block.w-full.mr-6(:class="{active: active}" slot-scope="{ setActive, active }"  v-click-outside="() => { if ($mq === 'desktop') return setActive(false) }")
      .dropdown__btn.w-full.border-2.border-gray-400.relative.text-base.text-gray.font-normal.py-select.pl-8.pr-16.text-left.inline-block.rounded.leading-10.overflow-hidden(class="hidden xl:block" @click.prevent="if ($mq === 'desktop') setActive(!active); return")
        .line-clamp-1 {{ label }}

      collapse-transition
        .dropdown__menu.w-full.rounded.top-dropdown.bg-white.pb-10.px-12.left-0.z-40(class="xl:min-w-dropdown-contrat xl:pt-8 xl:pb-16 xl:shadow xl:w-auto xl:absolute" v-show="active")
          p.title-400.mb-6 Type de transaction
          .grid.grid-cols-2.gap-4.mb-5
            label(class="radio-button inline-block relative text-base cursor-pointer select-none" v-for="contractType in contractTypes" :key="contractType.value")
              input(class="radio-button__input absolute opacity-0 cursor-pointer h-0 w-0" type="radio" name="contract_type" :value="contractType.value" v-model="contractTypeSelected")
              p(class="radio-button__checkmark btn-outline  btn-lg  btn-outline-primary transition duration-500 ease-in-out w-full")
                span {{ contractType.label }}

          .mb-5(class="md:flex" v-if="contractTypeSelected === 'ventes'")
            div(class="mt-2 mb-4 md:mb-0")
              label(class="checkbox-native inline-block font-sans relative pl-10 mr-12 text-base font-light text-gray-800 cursor-pointer select-none" v-for="accommodationAge in accommodationAges" :key="accommodationAge.value")
                span {{ accommodationAge.label }}
                input(class="checkbox-native__input absolute opacity-0 cursor-pointer h-0 w-0" type="checkbox" name="by_ages" :value="accommodationAge.value" v-model="accommodationAgesSelected")
                span(class="checkbox-native__checkmark absolute top-1/2 transform -translate-y-1/2 left-0 w-6 h-6 border border-black rounded-sm")

            a(v-if="accommodationAgesSelected.includes('brand_new')" :href="programsRootPath" class="btn btn-sm btn-primary px-3") Programmes
          div
            a.link.link-underline(:href="companiesRootPath") Acheter pour mon entreprise

</template>

<script>
export default {
  props: {
    searchParams: {
      type: Object,
      required: true
    },

    contractTypes: {
      type: Array,
      required: true
    },

    accommodationAges: {
      type: Array,
      required: true
    },

    companiesRootPath: {
      type: String,
      required: true
    },

    programsRootPath: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      label: 'Acheter',
      contractTypeSelected: null,
      accommodationAgesSelected: []
    }
  },

  watch: {
    contractTypeSelected () {
      this.$store.commit('updateParams', { key: 'contract_type', value: this.contractTypeSelected })

      this.$store.dispatch('searchAccommodations')
        .then(({ contractTypesLabel }) => {
          this.label = contractTypesLabel
        })
    },

    accommodationAgesSelected () {
      this.$store.commit('updateParams', { key: 'by_ages', value: this.accommodationAgesSelected })

      this.$store.dispatch('searchAccommodations')
    }
  },

  mounted () {
    this.contractTypeSelected = this.searchParams.contract_type
    this.accommodationAgesSelected = Array.isArray(this.searchParams.by_ages) ? [...this.searchParams.by_ages] : []
    if (this.searchParams.contract_type === 'locations') {
      this.label = 'Louer'
    }

    this.$nextTick(() => {
      this.$store.commit('updateReady', 'contractType')
    })
  }
}
</script>
