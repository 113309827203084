import Vue from 'vue'
import store from '@store'
import axios from 'axios'

// Plugins
import '@plugins/tooltip'
import Transitions from 'vue2-transitions'
import VueMq from 'vue-mq'
import VueScrollTo from 'vue-scrollto'
import PrettyCheckbox from 'pretty-checkbox-vue'
import VueAgile from 'vue-agile'
import VueYouTubeEmbed from 'vue-youtube-embed'

// Directive
import vClickOutside from 'v-click-outside'

/**
 * Automatically register components in the "pages/application" folder.
*/
const files = require.context('./', true, /\.vue$/i)
files.keys().map(key => {
  const component = key.split('/').pop().split('.')[0]

  Vue.component(component, () => import(`${key}`))
})

// Components
Vue.component('Lightgallery', () => import('@components/Lightgallery'))
Vue.component('ActiveItem', () => import('@components/ActiveItem'))
Vue.component('SelectExample', () => import('@components/SelectExample'))
Vue.component('AccommodationList', () => import('@components/AccommodationList'))
Vue.component('ProgramsMap', () => import('@components/ProgramsMap'))
Vue.component('ProgramMap', () => import('@components/ProgramMap'))
Vue.component('AgenciesMap', () => import('@components/AgenciesMap'))
Vue.component('AccommodationsMap', () => import('@components/AccommodationsMap'))
Vue.component('AccommodationMap', () => import('@components/AccommodationMap'))
Vue.component('AccordionGroup', () => import('@components/AccordionGroup'))
Vue.component('Accordion', () => import('@components/Accordion'))
Vue.component('Multiselect', () => import('vue-multiselect'))
Vue.component('SearchbarSelectOrder', () => import('@components/Searchbar/SelectOrder'))
Vue.component('AccommodationAlert', () => import('@components/AccommodationAlert'))
Vue.component('ExtendedAgile', () => import('@components/ExtendedAgile'))
Vue.component('ContactForm', () => import(/* webpackPrefetch: true */ '@components/ContactForm'))
Vue.component('ContactEventForm', () => import(/* webpackPrefetch: true */ '@components/ContactEventForm'))
Vue.component('ContactFormPopin', () => import(/* webpackPrefetch: true */ '@components/ContactFormPopin'))
Vue.component('YoutubeVideo', () => import('@components/YoutubeVideo'))
Vue.component('ActiveFavorite', () => import('@components/ActiveFavorite'))

Vue.use(Transitions)
Vue.use(VueMq, {
  breakpoints: {
    mobile: 1280,
    desktop: Infinity
  }
})
Vue.use(vClickOutside)
Vue.use(VueScrollTo)
Vue.use(PrettyCheckbox)
Vue.use(VueAgile)
Vue.use(VueYouTubeEmbed, { global: true })

// Configuration
Vue.config.performance = true
Vue.config.productionTip = false

document.addEventListener('DOMContentLoaded', () => {
  const token = document.head.querySelector('meta[name="csrf-token"]').content
  axios.defaults.headers.common['X-CSRF-Token'] = token
  axios.defaults.headers.common.Accept = 'application/json'
  axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

  axios.interceptors.response.use((response) => {
    return response
  }, function (error) {
    new FlashMessage("Une erreur s'est produite", "danger")
    return Promise.reject(error)
  })

  new Vue({
    el: '#vue-app',
    store
  })
})
