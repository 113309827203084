<template lang="pug">
  active-item(:is-active="$mq === 'mobile'")
    .dropdown.relative.inline-block.w-full.mr-6(:class="{active: active}" slot-scope="{ setActive, active }"  v-click-outside="() => { if ($mq === 'desktop') return setActive(false) }")
      .dropdown__btn.w-full.border-2.border-gray-400.relative.text-base.text-gray.font-normal.py-select.pl-8.pr-16.text-left.inline-block.rounded.leading-10.overflow-hidden(class="hidden xl:block" @click.prevent="if ($mq === 'desktop') setActive(!active); return")
        .line-clamp-1 {{ label }}

      collapse-transition
        .dropdown__menu.w-full.rounded.top-dropdown.bg-white.left-0.z-40.pb-10.px-12(class="xl:min-w-dropdown-accommodation-types xl:py-8 xl:shadow xl:w-auto xl:absolute" v-show="active")
          p.title-400.mb-6 Typologie
          .flex.flex-wrap
            label(class="radio-button inline-block mr-4 pb-2 relative text-base cursor-pointer select-none" v-for="accommodationType in accommodationTypes" :key="accommodationType.value")
              input(class="radio-button__input absolute opacity-0 cursor-pointer h-0 w-0" type="checkbox" name="accommodation_type" :value="accommodationType.value" v-model="selectedValues")
              p(class="radio-button__checkmark btn-outline  btn-lg  btn-outline-primary transition duration-500 ease-in-out") {{ accommodationType.label }}
</template>

<script>
export default {
  props: {
    initialAccommodationTypes: {
      type: Array,
      default: () => {
        return []
      }
    },

    accommodationTypes: {
      type: Array,
      required: true
    }
  },

  data () {
    return {
      selectedValues: [],
      label: 'Typologie'
    }
  },

  watch: {
    selectedValues () {
      this.$store.commit('updateParams', { key: 'by_accommodation_types', value: this.selectedValues })

      this.$store.dispatch('searchAccommodations')
        .then(({ accommodationTypesLabel }) => {
          this.label = accommodationTypesLabel
        })
    }
  },

  mounted () {
    this.selectedValues = [...this.initialAccommodationTypes]

    this.$nextTick(() => {
      this.$store.commit('updateReady', 'accommodationTypesCheckboxes')
    })
  }
}
</script>
